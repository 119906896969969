import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {Observable} from "rxjs";
import {ScheduleSlot} from "../models/scheduleSlot";

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  public apiEndpoint: string = 'appointments';

  constructor(protected apiService: RestApiService) {
  }

  public getAppointmentsByClient(id: number): Observable<any> {
    return this.apiService.get(`${this.apiEndpoint}/client`, id);
  }

  public addAppointment(payload): Observable<any> {
    return this.apiService.post(`${this.apiEndpoint}/add`, payload);
  }

  deleteAppointmentById(id: number) {
    return this.apiService.delete(this.apiEndpoint, id);
  }

  bookAppointmentToPlanning(planningId: number)
  {
    return this.apiService.put(`${this.apiEndpoint}/book-planning`, planningId, null);
  }

  unbookAppointmentToPlanning(planningId: number)
  {
    return this.apiService.put(`${this.apiEndpoint}/unbook-planning`, planningId, null);
  }
}
